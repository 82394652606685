import React, {useEffect, useState} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {ADVERTORIAL_BASE_URL} from '../constants'
import {formatAdvertorialGifs} from '../util/advertorialImages'
import {isCampaignSourceFrom} from '../util/campaignSource'
import Layout from '../components/layout'

import Advertorial from '../components/templates/sp-644-advertorial/advertorial'

const PriceSearch = () => {
    const images = useStaticQuery(graphql`
        query AdvertorialPriceSearch {
            hero: file(relativePath: {eq: "advertorial/shopping/shopping-hero.png"}) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            allFile(filter: {base: {glob: "*shopping*"}, extension: {eq: "gif"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
        }
    `)

    const [ctaUrl, setCtaUrl] = useState(`https://www.startpage.com`)

    useEffect(() => {
        if (isCampaignSourceFrom('taboola')) {
            // pass on all query parameters to startpage.com (so pysearch can ingest them)
            setCtaUrl(`https://www.startpage.com${window.location.search}`)
        }
    }, [ctaUrl])

    const seo = {
        title: `Avoid Higher Online Prices Based on Your Search History | Startpage`,
        description: `Beware non-private search engines are setting unfair price trackers on people. Learn how to avoid higher prices and protect your online privacy with Startpage.`,
        ogImagePath: `${ADVERTORIAL_BASE_URL}${images.hero.childImageSharp.fluid.src}`,
        ogUrl: `${ADVERTORIAL_BASE_URL}/price-search/`,
    }

    // see https://www.gatsbyjs.org/packages/gatsby-source-filesystem/ for querying / working with gifs in gatsby
    const sectionImages = formatAdvertorialGifs(images)

    const section1Text1 = `
        <p>If you think retailers aren’t tracking your online behavior and are giving you the best price they can offer, think again! Over the last few years, more and more companies are practicing dynamic pricing. From Expedia to Amazon to Match.com, consumers have reported seeing vastly different search results and average prices. As a consumer, it’s almost impossible to tell
        if the search results you see have been profiled or
        if cheaper options have been hidden or buried.</p>

        <p>So, what should you do?</p>

        <p>Experts from all corners of the internet have been advising that people“ start searching in private! ”Thankfully, the brilliant engineers from the Netherlands have found a solution with Startpage, the world’s most private search engine.</p>
    `
    const section1Text2 = `
        <p>Using your personal search engine data, companies can easily implement dynamic pricing by placing cookies on your computer. This results in higher prices or fewer discounts than other shoppers based on your home address, gender, race and even mental health status. According to Privacy Rights Clearinghouse and the Harvard Business Review, consumers need to be aware of dynamic pricing and profiled prices.*</p>

        <p>Take the target off your back, get fair pricing, and start searching in private! Startpage is the world’s most private search engine that delivers Google search results through their proprietary anonymizing process.</p>
    `
    const section2Text1 = `
        <p>You can also view entire websites without leaving a trace or becoming a victim of dynamic pricing by using Startpage’s ‘Anonymous View’ feature. Fast Company says, “This alternative search engine is basically Google without the privacy headaches.”</p>
    `
    const section3Text1 = `
        <p>Stop tracking and targeting. Start with Startpage!</p>

        <p>Do you know what the best part about Startpage is? It’s completely FREE! Try it out now.</p>
    `
    const bottomLinks = `
        <ul>
            <li>
                Privacy Rights Clearing House:
                <a href="https://privacyrights.org/consumer-guides/online-shopping-tips">https://privacyrights.org/consumer-guides/online-shopping-tips</a>
            </li>
            <li>
                Harvard Business Review:
                <a href="https://hbr.org/2017/10/how-retailers-use-personalized-prices-to-test-what-youre-willing-to-pay">https://hbr.org/2017/10/how-retailers-use-personalized-prices-to-test-what-youre-willing-to-pay</a>
            </li>
            <li>
                Washington Post:
                <a href="https://www.washingtonpost.com/posteverything/wp/2014/11/03/if-you-use-a-mac-or-an-android-e-commerce-sites-may-be-charging-you-more/">https://www.washingtonpost.com/posteverything/wp/2014/11/03/if-you-use-a-mac-or-an-android-e-commerce-sites-may-be-charging-you-more/</a>
            </li>
        </ul>
    `

    const contentData = {
        sections: [
            {
                title: 'Why Privacy is Important When Shopping and Searching Online',
                image: images.hero.childImageSharp.fluid,
            },
            {
                title: 'Non-Private Search Engines Are Selling Your Search History and Digital Profile',
                text1: section1Text1,
                text2: section1Text2,
            },
            {
                title: 'How Private Search Works: No Price Tracking, No Cookies, No Targeting, No Search History',
                image: sectionImages[0],
                text1: section2Text1,
            },
            {
                title: 'Startpage’s Private Browsing Feature ‘Anonymous View’',
                image: sectionImages[1],
                text1: section3Text1,
            },
            {
                text1: bottomLinks,
            },
        ],
    }
    return (
        <Layout>
            <Advertorial content={contentData} ctaUrl={ctaUrl} seo={seo} />
        </Layout>
    )
}

export default PriceSearch
